<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <md-tabs md-alignment="left">
        <md-tab id="tab-home" md-label="Asig. de referencias">
          <List class="margin-fix" @selected="grabSelection" />
          <ReferenceList :selection="selected" />
        </md-tab>
      </md-tabs>
    </div>
  </div>
</template>
<script>
import ReferenceList from '@/components/References/References.vue'
import List from '@/components/References/List.vue'
import { mapGetters, mapActions } from 'vuex'
import auth from '@/mixins/auth'
export default {
  name: 'References',
  mixins: [auth],
  components: {
    List, ReferenceList
  },
  data() {
    return {
      selected: null
    }
  },
  mounted() {
    this.brands()
  },
  methods: {
    ...mapActions({product: 'products/getProduct', references: 'references/getReferences', brands: 'brands/getBrands'}),
    grabSelection(val) {
      this.references(val)
      this.selected = val
    }
  }
}
</script>
