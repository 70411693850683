<template>
  <div>
    <Loader v-if="loader"/>
    <div v-else>
      <div class="md-list-item-text space-down">
        <span class="md-display-1 title-fix title-black-fix">Productos</span><br>
        <span class="md-caption">Listado de Productos registrados</span>
      </div>
      <div class="md-layout md-alignment-center margin-fix">
        <div class="md-layout-item"></div>
        <div class="md-layout-item md-size-80">
          <md-field>
            <md-select v-model="product">
              <md-option v-for="item in list" :value="item.id">{{item.sku}}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item"></div>
      </div>
    </div>
  </div>
</template>
<script>

import Loader from '@/components/Common/Loader.vue'
import Empty from '@/components/Common/Empty.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'List',
  components: {
    Loader, Empty
  },
  data() {
    return {
      product: '',
      loader: true
    }
  },
  created() {
    this.products().then(() => {
      this.loader = false
    })
  },
  computed: {
    ...mapGetters({list: 'products/listProducts'})
  },
  methods: {
    ...mapActions({products: 'products/getProducts'})
  },
  watch: {
    product(val) {
      this.$emit('selected', val)
    }
  }
}
</script>
