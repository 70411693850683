<template>
  <div class="md-layout md-alignment-center margin-fix">
    <div class="md-layout-item md-size-80" v-if="selection">
      <h2>Referencias</h2>
      <p>Referencias del producto</p>
      <md-button @click="showDialog = true">Crear nueva</md-button>
      <md-dialog :md-active.sync="showDialog">
      <md-dialog-title>Nueva referencia</md-dialog-title>
      <div class="md-layout md-alignment-center margin-fix">
        <p>Genera una nueva referencia para tu producto</p>
        <div class="md-layout-item md-size-80">
          <md-field class="input-fix">
            <label for="brand-name">Nombre de marca</label>
            <md-select name="brand-type" id="brand-type" v-model="$v.reference.brand_id.$model">
              <md-option v-for="brand in list" :value="brand.id">{{brand.brand}}</md-option>
            </md-select>
          </md-field>
          <div v-if="$v.reference.brand_id.$dirty">
            <span class="md-helper-text" v-if="!$v.reference.brand_id.required">Campo requerido</span>
          </div>
        </div>
      </div>
      <div class="md-layout md-alignment-center margin-fix">
        <div class="md-layout-item md-size-80">
          <md-field class="input-fix">
            <label for="first-name">Nombre de referencia</label>
            <md-input name="reference" id="reference" v-model="$v.reference.reference.$model" />
          </md-field>
          <div v-if="$v.reference.reference.$dirty">
            <span class="md-helper-text" v-if="!$v.reference.reference.required">Campo requerido</span>
          </div>
        </div>
      </div>
      <md-dialog-actions class="margin-fix">
        <md-button class="md-primary" @click="onCancel">Cerrar</md-button>
        <md-button class="md-primary" @click="onConfirm">Crear</md-button>
      </md-dialog-actions>
      </md-dialog>
      <div class="md-layout-item">
        <md-table>
          <md-table-row>
            <md-table-head md-numeric>ID</md-table-head>
            <md-table-head>Producto</md-table-head>
            <md-table-head>Referencia</md-table-head>
            <md-table-head>Marca</md-table-head>
            <md-table-head>Eliminar</md-table-head>
          </md-table-row>
          <md-table-row v-if="products.length < 1">
            <Empty />
          </md-table-row>
          <md-table-row v-for="product in products">
            <md-table-cell md-numeric>{{product.id}}</md-table-cell>
            <md-table-cell>{{product.product.sku}}</md-table-cell>
            <md-table-cell>{{product.reference}}</md-table-cell>
            <md-table-cell>{{product.brand.brand}}</md-table-cell>
            <md-table-cell>
              <md-button class="md-icon-button" @click="deleteReference(product.id)">
                <md-icon>delete</md-icon>
              </md-button>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </div>
    </div>
  </div>
</template>
<script>
import validations from '@/validations/references'
import Empty from '@/components/Common/Empty.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'References',
  props: ['selection'],
  components: {
    Empty
  },
  data() {
    return {
      showDialog: false,
      reference: {
        brand_id: '',
        reference: ''
      }
    }
  },
  validations: validations,
  computed: {
    ...mapGetters({products: 'references/listReferences', list: 'brands/listBrands'})
  },
  methods: {
    ...mapActions({deleteReference: 'references/deleteReference'}),
    onConfirm () {
      if (!this.$v.$invalid) {
        this.reference.product_id = this.$props.selection
        this.$store.dispatch('references/addReference', this.reference)
        this.showDialog = false
        this.cleanUp()
      }
    },
    cleanUp() {
      this.$data.reference = this.$options.data.apply(this).reference
      this.$v.$reset()
    },
    onCancel () {
      this.showDialog = false
    }
  }
}
</script>
